import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import { BgImage } from "gbimage-bridge"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import SgHeadings from "../components/sg-headings"
import Cta from "../components/call_to_action"
// Service page data from collection

// Add Page Head component and pass in SEO variables from GraphQL
export const Head = ({ data }) => {
  const title = data.strapiServicePage.headline 
  const description = data.strapiServicePage.heroText 
  return (
    <SEO title={title} description={description} />
  )
}

export default function servicePageDetails( {data} ) {

// declare variables

  const sv = data.strapiServicePage 
  const headline = sv.headline
  const heroText = sv.heroText
  const heroImage = getImage(sv.heroImage.localFile)
  const headlineOne = sv.headlineOne
  const paraOne = sv.copyOne
  const headlineTwo = sv.headlineTwo
  const paraTwo = sv.copyTwo
  const imageOne = getImage(sv.imageOne.localFile)
  const imageTwo = getImage(sv.imageTwo.localFile)
  const testimonials = sv.testimonials
  
  // Return the page

  return(
    <Layout>
      <div className="relative w-full h-full bg-gradient-to-r from-gray-800 to-red-700">
            <BgImage 
              image={heroImage} 
              alt="Hero background image jackson.fi" 
              className="absolute h-96 bg-center object-cover mix-blend-overlay" />
                <div className="container absolute top-0 left-1/2 -translate-x-1/2">
                  <SgHeadings title={headline} description={heroText} />
                </div>  
          </div>
          <div className="container pt-6 pb-6 m-auto px-6 text-gray-600 md:px-12 xl:px-6">
            <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                    <div className="md:w-6/12">
                    <GatsbyImage className="rounded-3xl shadow-2xl" image={imageOne} alt="Fractional Analytics and Strategy" />
                    </div>
                    <div className="md:w-6/12">
                        <div className="p-2 text-3xl md:text-4xl font-bold"><ReactMarkdown>{headlineOne}</ReactMarkdown></div>
                       <div className="p-2 space-y-4 prose"><ReactMarkdown>{paraOne}</ReactMarkdown></div>
                  </div>
            </div>
          </div>
          <div className="container pt-6 pb-6 m-auto px-6 text-gray-600 md:px-12 xl:px-6">
            <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                    <div className="md:w-6/12">
                    <div className="p-2 text-3xl md:text-4xl font-bold"><ReactMarkdown>{headlineTwo}</ReactMarkdown></div>
                       <div className="p-2 space-y-4 prose"><ReactMarkdown>{paraTwo}</ReactMarkdown></div>
                  </div>
                  <div className="md:w-6/12">
                    <GatsbyImage className="rounded-3xl shadow-2xl" image={imageTwo} alt="Fractional Analytics and Strategy" />
                    </div>
            </div>
          </div>
          <div className="container grid grid-cols-1 md:grid-cols-2 gap-4 pb-4">
           {testimonials.map(testimonial => (
           <figure key={testimonial.id} className="container mt-12 bg-slate-100 shadow-md rounded-2xl pt-2 relative">
            <GatsbyImage className="w-24 h-24 md:w-36 md:h-auto rounded-full float-right" 
            image={testimonial.headshot.localFile.childImageSharp.gatsbyImageData} 
            alt="Testimonial From Client or Partner" 
            />
            <div className="pt-6 md:p-6 space-y-4">
                <blockquote>
                <div className="text-base font-normal">
                   <ReactMarkdown>{testimonial.body.data.body}</ReactMarkdown>
                 </div>
                </blockquote>
                <figcaption className="font-medium">
                <div className="text-slate-700">
                    {testimonial.name}
                </div>
                <div className="text-red-400">
                    {testimonial.title}
                </div>
                </figcaption>
            </div>
            </figure>
          ))}
          </div>
      <Cta />
    </Layout>
  )
}

// Get the data from strapi single product data related to slug
export const query = graphql`
query ($slug: String) {
  strapiServicePage (slug: {eq: $slug}) {
    headline
    heroText
    headlineOne
    headlineTwo
    copyOne
    copyTwo
    heroImage {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    imageOne {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    imageTwo {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    testimonials {
      body {
        data {
          body
        }
      }
      headshot {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      title
      name
      id
    }
  }
}
`
